/* ==========================================================================
    IE identification
  ========================================================================== */


if (document.documentMode) {
  document.documentElement.className += ' ie' + document.documentMode;
}


$(document).ready(function () {

  $('.btn--menu').on('click', function(e) {           
    $('.main-menu').toggleClass('open');            
  });

  $('.btn-search').on('click', function(e) {        
    $(this).parent('.form-group').toggleClass('open');  
    e.preventDefault();           
  });

  /* 
  ==========================================================================
    BREADCRUMBS
  ========================================================================== */

  hide = true;
  $('body').on("click", function (e) {
      if (hide) $('.dropdown').removeClass('show');
      hide = true;
  });

  $('body').on('click', '.dropdown__btn', function (ee) {
    ee.preventDefault();
      var self = $(this).parents('.dropdown');

      if (self.hasClass('show')) {
          $('.dropdown').removeClass('show');
          return false;
      }

      $('.dropdown').removeClass('show');

      self.toggleClass('show');
      hide = false;
  });


  /* 
  ==========================================================================
    HERO
  ========================================================================== */

  var mySwiperHero = new Swiper ('.hero', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: 0, 
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.hero .hero__footer .swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    }
  });

  
  /* 
  ==========================================================================
    PRODUCT SLIDER
  ========================================================================== */

  var mySwiperProduct = new Swiper ('.product-slider', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 30,
    initialSlide: 0, 
    allowTouchMove: true,
    centerMode: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.product-slider .product-slider__footer .swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
    breakpoints: {
      500: {
        slidesPerView: 2,
        spaceBetween: 20,
        allowTouchMove: true,
      },
      800: {
        slidesPerView: 3,
        spaceBetween: 20,
        allowTouchMove: true,
      },
      1100: {
        slidesPerView: 4,
        spaceBetween: 35,
      },
    }
  });

  /* 
  ==========================================================================
    FEATURE BOX LEFT
  ========================================================================== */

  var mySwiperBlog = new Swiper ('.blog', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: 0, 
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.blog__footer .swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
    breakpoints: {
      500: {
        slidesPerView: 2,
        allowTouchMove: true,
      },
      800: {
        slidesPerView: 3,
        allowTouchMove: true,
      },
      1100: {
        slidesPerView: 4,
      },
    }
  });

  /* 
  ==========================================================================
    FEATURE BOX RIGHT
  ========================================================================== */

  var mySwiperFeatureRight = new Swiper ('.slider-right .feature-box__slider', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: 0, 
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.slider-right .swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
  });
  
  
  /* 
  ==========================================================================
    RANGE SLIDER ONE
  ========================================================================== */

  var sliderOneValue = document.getElementById('range-1');
  var sliderOneInput = document.getElementById('range-keypress-1');
  var sliderOneInputs = [sliderOneInput];

  noUiSlider.create(sliderOneValue, {
      start: 20,
      connect: 'lower',
      step: 1,
      tooltips: [wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: ' Kč'
      })],
      range: { 
          'min': 0,
          'max': 1000000
      },
      format: wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: ' Kč'
    })
  });
  sliderOneValue.noUiSlider.on('update', function (values, handle) {
    sliderOneInputs[handle].value = values[handle];
  });

  /* 
  ==========================================================================
    RANGE SLIDER TWO
  ========================================================================== */

  var sliderTwoValue = document.getElementById('range-2');
  var sliderTwoInput = document.getElementById('range-keypress-2');
  var sliderTwoInputs = [sliderTwoInput];

  noUiSlider.create(sliderTwoValue, {
      start: 20,
      connect: 'lower',
      step: 1,
      tooltips: [wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: ' Kč'
      })],
      range: { 
          'min': 0,
          'max': 10000
      },
      format: wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: ' Kč'
    })
  });
  sliderTwoValue.noUiSlider.on('update', function (values, handle) {
    sliderTwoInputs[handle].value = values[handle];
  });

  /* 
  ==========================================================================
    RANGE SLIDER THREE
  ========================================================================== */

  var sliderThreeValue = document.getElementById('range-3');
  var sliderThreeInput = document.getElementById('range-keypress-3');
  var sliderThreeInputs = [sliderThreeInput];

  noUiSlider.create(sliderThreeValue, {
      start: 20,
      connect: 'lower',
      step: 1,
      tooltips: [wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: ''
      })],
      range: { 
          'min': 0,
          'max': 320
      },
      format: wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: ' měsíců'
    })
  });
  sliderThreeValue.noUiSlider.on('update', function (values, handle) {
    sliderThreeInputs[handle].value = values[handle];
  });

});



